.accordion-list {
  list-style: none;
  margin: 0;
  padding: 0;
  max-width: 30rem;
  border-radius: 0.4rem;
  overflow: hidden;
  box-shadow: 0 0 0.8rem 0.1rem rgba(15, 72, 179, 0.06),
  0 20px 30px -10px rgba(15, 72, 179, 0.2);
}

.accordion-item {
  border-top: 1px solid rgba(0, 0, 0, 0.2);
}

.accordion-item:last-of-type {
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}

[data-mode="dark"] .accordion-item,
[data-mode="dark"] .accordion-item:last-of-type {
  border-color: rgba(255, 255, 255, 0.2);
}

.accordion-list__item + .accordion-list__item {
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}


.accordion-item--opened .accordion-item__icon {
  transform: rotate(180deg);
}

.accordion-item--opened .accordion-item__inner {
  max-height: 100rem;
  transition-timing-function: cubic-bezier(0.895, 0.03, 0.685, 0.22);
  transition-duration: 0.5s;
  transition-property: max-height;
}

.accordion-item--opened .accordion-item__content {
  opacity: 1;
  transform: translateY(0);
  transition-delay: 0.2s;
  transition-timing-function: ease-in-out;
  transition-duration: 0.2s;
  transition-property: opacity, transform;
}

.accordion-item__line {
  padding: 1.5rem 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 2;
  position: relative;
  text-transform: uppercase;
}

.accordion-item__line:hover {
  cursor: pointer;
}

.accordion-item__title {
  font-size: 1rem !important;
  margin: 0;
  font-weight: 500;
}

.accordion-item__icon {
  width: .95rem;
  height: .7rem;
  transition: transform 0.3s ease-in-out;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTQiIGhlaWdodD0iNiIgdmlld0JveD0iMCAwIDE0IDYiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGZpbGwtcnVsZT0iZXZlbm9kZCIgY2xpcC1ydWxlPSJldmVub2RkIiBkPSJNNy4wMjA4MSAzLjcyOTYzTDEyLjc5OTUgMC4wMzcwNTY0TDE0IDEuNDUwNTNMNy4wMTg3OSA2TDAgMS40MTE2N0wxLjIwMzA1IDBMNy4wMjA4MSAzLjcyOTYzWiIgZmlsbD0iIzIyMjIyMiIvPgo8L3N2Zz4K);
}

.accordion-item__inner {
  max-height: 0;
  overflow: hidden;
  text-transform: cubic-bezier(0.95, 0.05, 0.795, 0.035);
  transition-duration: 0.5s;
  transition-property: max-height;
  z-index: 1;
  position: relative;
}

.accordion-item__content {
  opacity: 0;
  transform: translateY(-1rem);
  transition-timing-function: linear, ease;
  transition-duration: 0.1s;
  transition-property: opacity, transform;
  transition-delay: 0.5s;
  padding: 0;
}

[data-mode="clear"] .accordion-item__paragraph {
  color: #333;
}

.accordion-item__paragraph {
  margin: 0;
  font-size: 1rem;
  font-weight: 300;
  line-height: 1.3;
}

.accordion-item__paragraph > div > * {
  margin-bottom: 1rem;
}

[data-mode="dark"] .accordion-item__line {
  background-color: #2e3133;
}

[data-mode="dark"] .accordion-item__icon {
  filter: invert(1);
}

[data-mode="dark"] .accordion-item__title,
[data-mode="dark"] .accordion-item__paragraph {
  color: white;
}
