.cookies__content {
  position: fixed;
  bottom: 20px;
  right: 20px;
  display: block;
  background-color: #212425;
  color: white;
  max-width: 25rem;
  width: 80%;
  border-radius: 16px;
  z-index: 99;
}

.cookies__wrap {
  padding: 1.7rem;
}

.cookies__title {
  font-size: 15px;
  font-style: normal;
  font-weight: bold;
  text-transform: uppercase;
}


.dialog__title--cookies {
  font-size: 1.75rem;
  text-transform: none;
  font-weight: 500;
}

.cookies__text {
  font-size: 15px;
  line-height: 22px;
  margin: 15px 0;
}
.cookies__buttons {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.cookies__button-gray {
  background-color: #d3d7d7;
  color: #212425;
}

.cookies__button-main {
  color: white;
  background-color: #77bc1f;
}

.cookies__button-main,
.cookies__button-gray {
  border-radius: 8px;
  padding: 0.7rem;
  display: flex;
  justify-content: center;
  width: 45%;
  border: 0;
  font-size: 1rem;
}

.cookies-settings__text {
  margin: 2rem 0;
  font-size: 1rem;
}

[data-mode="dark"] .cookies__content {
  background-color: #d3d7d7;
  color: #222222;
}

[data-mode="dark"] .cookies__button-gray {
  background-color: #4d5356;
  color: white;
}

@media screen and (min-width: 551px) {
  .cookies-settings__bottom {
    display: none !important;
  }
}

@media screen and (max-width: 551px) {
  .cookies__content {
    left: 0;
    right: 0;
    margin: auto;
    min-width: 360px;
  }

  .cookies__buttons {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-top: 10px;
  }
  .cookies__button-gray,
  .cookies__button-main {
    width: 100%;
    margin: 0.25rem 0;
  }

  .cookies-settings__bottom {
    position: fixed !important;
    right: 20px;
    z-index: 999;
    bottom: 20px
  }
}
